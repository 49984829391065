import React from "react";
import Particles from "react-particles-js";

import SEO from "../components/seo"

const Socialicons = React.lazy(() =>
  import("../components/Socialicons")
)

function Home(){
  const isSSR = typeof window === "undefined"

  const paramConfig = {
    particles: {
      number: {
        value: 160,
        density: {
          enable: false
        }
      },
      color: {
        value: "#ffffff"
      },
      opacity: {
        value: 0.1
      },
      size: {
        value: 5,
        random: true,
        anim: {
          speed: 4,
          size_min: 0.3
        }
      },
      line_linked: {
        enable: false
      },
      move: {
        random: true,
        speed: 1,
        direction: "top",
        out_mode: "out"
      }
    }
  };
  
  return (
    <>
      <SEO title="Home" />
      <div className="mi-home-area mi-padding-section">
        <Particles className="mi-home-particle" params={paramConfig} />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-12">
              <div className="mi-home-content">
                <h1>
                  Hi, I am <span className="color-theme">Steve Lambe</span>
                </h1>
                <p>I am a web and mobile applications developer with some knowledege in DevOps and security.</p>
                {!isSSR && (
                  <React.Suspense fallback={<div />}>
                    <Socialicons />
                  </React.Suspense>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
